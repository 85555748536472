/*########################################
/#####RESET ELEMENTS
########################################*/
* {
  margin: 0; padding: 0; box-sizing: border-box;
}
ul {
  list-style: none; list-style-type: none;
}
ul li {
  float: left;
}
img, fieldset {
  border: none;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: normal; margin-bottom: 10px;
}
/*########################################
/#####FONT FILES
########################################*/
@font-face {
  font-family: 'Metropolis';
  src: url('font-files/metropolis-bold-webfont.woff2') format('woff2'),
       url('font-files/metropolis-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

/*########################################
/#####MAIN ELEMENTS
########################################*/
body {
  background-color: #030303; font-family: 'Metropolis';
  font-weight: 600; color: #FFF; height: 100vh;
  max-height: -webkit-fill-available;
  // background: linear-gradient(180deg, #ceedff 0%, #00adef 100%);
  
}
h1 {}
h2 {}
h3 {}
p {
  float: left; margin-bottom: 10px; width: 100%;
}
