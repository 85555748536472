.wrapper {
	max-width: 600px; margin: auto; background: #000; 
	height: 100vh; position: relative;
	box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 30%);
	max-height: -webkit-fill-available;
	.banner-image {
		background-size: cover; background-repeat: no-repeat;
		background-position: center; left: 0px; right: 0px; 
		position: absolute; bottom: 25%; top: 0px;
		&::before {
			content: ''; left: 0px; right: 0px; bottom: 0px; top: 0px;
			background: linear-gradient(0deg, #000000 0%, rgb(0 0 0 / 20%) 70%);
			position: absolute;
		}
	}
	.content {
		position: absolute; left: 20px; right: 20px; 
		color: #FFF; bottom: 20px;
		h1 {
			float: left; width: 100%; margin-bottom: 10px;
			text-align: center;
		}
		.btn-main {
			// width: 100%; padding: 10px; color: #FFF; background-color: #353535; 
			// display: flex; border-radius: 120px; float: left; align-items: center;
			// margin-top: 15px; text-decoration: none;
			// transition: all 0.5s ease; -webkit-transition: all 0.5s ease;
			align-items: center; color: #fff; display: flex; float: left; 
			margin-top: 5px; padding: 10px; text-decoration: none; 
			transition: all .5s ease; -webkit-transition: all .5s ease; 
			width: 100%; border-top: 1px solid #fff3; border-bottom: 1px solid #fff3; 
			background: linear-gradient(270deg, #00000021 0%, #0000000a 100%); 
			font-size: 16px;
			&.btn-onlyfans {
				background: #009fdb; color: #FFF;
			}
			&:hover {
				transform: scale(1.05);
			}
			.circle {
				width: 40px; height: 40px; 
				//border-radius: 100%;
				background-size: cover; background-repeat: no-repeat;
				background-position: center;
				&.ico-twitter {
					background-image: url('../images/ico-twitter.jpg');
				}
				&.ico-tiktok {
					background-image: url('../images/ico-tiktok.jpg');
				}
				&.ico-reddit {
					background-image: url('../images/ico-reddit.jpg');
				}
				&.ico-onlyfans {
					background-image: url('../images/ico-onlyfans.jpg');
				}
				&.ico-instagram {
					background-image: url('../images/ico-instagram.jpg');
				}
			}
			span {
				text-transform: uppercase; margin-left: 15px;
			}
			
		}
	}
	.not-found {
		text-align: center; display: flex; align-items: center; justify-content: center; 
		position: absolute; left: 0; right: 0; top: 0; bottom: 0;
	}
}

@media screen and (max-width: 600px) {
	body {
		background: #000;
	}
}